<template>
  <footer class="bg-blue text-white text-left">
    <div class="container px-md-0">
      <div class="row d-flex justify-content-center">
        <div class="col-md-4 mb-4 mb-md-0">
          <b-img
            class="logo mb-2 h-auto"
            alt="melo-white"            
            :src="require('@/assets/logo/melo-white.svg')"
          ></b-img>
          <ul class="opacity-low">
            <li>
              <b-link to="/privacy/" @click.native="$scrollToTop"
                >Politique de confidentialité</b-link
              >
            </li>
            <li>
              <b-link to="/terms/" @click.native="$scrollToTop">CGUV</b-link>
            </li>
            <li>Paiement sécurisé</li>
          </ul>
          <div class="d-flex flex-row opacity-low">
            <IconVisa class="mr-2" />
            <IconMastercard class="mr-2" />
            <IconCreditCard />
          </div>
        </div>
        <div class="col-md-8">
          <div class="row links">
            <div class="col-md-3">
              <h5>Melo</h5>
              <ul>
                <!--  <li>À propos</li> -->
                <li>
                  <b-link to="/blog/" @click.native="$scrollToTop">Blog</b-link>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.melo.io/assets/docs/presskit.pdf"
                    >Presse</a
                  >
                </li>
                <!--<li>Recrutement</li> -->
                <li>
                  <b-link to="/affiliation/" @click.native="$scrollToTop"
                    >Programme d'affiliation</b-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-md-3">
              <h5>Plans</h5>
              <ul>
                <li>
                  <b-link to="/pricing/" @click.native="$scrollToTop">Tarifs</b-link>
                </li>
                <li>
                  <b-link to="/api/" @click.native="$scrollToTop">API données immobilières</b-link>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.make.com/en/register?pc=virus"
                    >Module Make</a
                  >
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://zapier.com/apps/melo/integrations"
                    >Module Zapier</a
                  >
                </li>
                
                <li>
                  <b-link to="/generation-de-leads/" @click.native="$scrollToTop">Génération de leads</b-link>
                </li>
              
              </ul>
            </div>
            <div class="col-md-3">
              <h5>Aide</h5>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://docs.melo.io"
                    >Documentation API</a
                  >
                </li>
                <li >
                  <div class="cursor-pointer" @click="openChat">Nous contacter</div>
                </li>
                <li>
                  <b-link to="/status/" @click.native="$scrollToTop">Status</b-link>
                </li>
                <!--  <li>Centre d'aide</li>
                <li>Besoin d'un chasseur ?</li>
                <li>Les secteurs couverts</li> -->
              </ul>
            </div>
            <div class="col-md-3">
              <h5>Suivez-nous</h5>
              <ul>
                <li>
                  <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/melo-io/"
                    >LinkedIn</a
                  >
                </li>
                <li>
                  <a target="_blank" rel="noopener" href="https://melo-io.medium.com/">Medium</a>
                </li>               
              </ul>             
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconVisa from "@/components/icons/IconVisa.vue";
import IconCreditCard from "@/components/icons/IconCreditCard.vue";
import IconMastercard from "@/components/icons/IconMastercard.vue";
export default {
  components: {
    IconMastercard,
    IconVisa,
    IconCreditCard,
  },
  methods: {
    openChat() {
      window.$chatwoot.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  * {
  letter-spacing: 1px;
}
  a {
    text-decoration: none;
    color: inherit;
  }
  h5 {
    font-weight: bold;
  }
  padding: 2rem 1rem 2rem 1rem;
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  ul {
    padding-left: 0;
    li {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }
  .small-text {
    font-size: 0.8rem;
  }

  .links {
    li {
      opacity: 0.7;
    }
  }
}
</style>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
footer {
  svg {
    height: 2.5rem;
    width: 2.5rem;
    display: block;
    path {
      fill: white !important;
    }
  }
}
</style>
